<template>
  <div class="flex flex-col flex-1 overflow-hidden border border-gray-300 rounded">
    <div class="relative flex-1 overflow-y-scroll">
      <p-loader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.Id')"></th>
            <th v-html="$tk('Reports.Name')"></th>
            <th v-html="$tk('Reports.GLN')"></th>
            <th v-html="$tk('Reports.Location')"></th>
            <th v-html="$tk('Common.General.Phone')"></th>
            <th v-html="$tk('Common.General.Email')"></th>
            <th v-html="$tk('Common.General.Language')"></th>
            <th v-html="$tk('Reports.Active')"></th>
            <th v-html="$tk('Reports.SuperUser')"></th>
            <th v-html="$tk('Reports.CustomerAdmin')"></th>
            <th v-html="$tk('Reports.ReportLevel')"></th>
            <th v-html="$tk('Common.General.Created')"></th>
            <th v-html="$tk('Reports.LastLogon')"></th>
            <th v-html="$tk('Reports.LastConfirm')"></th>
            <th v-html="$tk('Reports.Info')"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :class="user.userStatusId !== 1 ? 'inactive' : ''" :key="index">
            <td>{{user.loginName}}
            <td>{{user.name}}</td>
            <td>{{user.gln}}</td>
            <td class="whitespace-no-wrap">{{user.locationName}}</td>
            <td>{{user.phone}}</td>
            <td>{{user.email}}</td>
            <td class="whitespace-no-wrap">{{user.language}}</td>
            <td>{{user.userStatusId === 1 ? 'Ja' : ''}}</td>
            <td>{{user.isCustomerContact ? 'Ja' : ''}}</td>
            <td>{{user.isCustomerAdmin ? 'Ja' : ''}}</td>
            <td>{{user.reportLevel === 1 ? 'Kunde' : 'Lokasjon'}}</td>
            <td>{{ user.created | date }}</td>
            <td class="whitespace-no-wrap">{{ user.lastLogonAx | dateAndTime }}</td>
            <td>{{ user.modified | date }}</td>
            <td>{{ user.info }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="header">
      <div class="ml-1 text-xl font-medium text-gray-900">{{$tk('Reports.Users', true)}}</div>
    </portal>

    <portal to="actions">
      <div class="flex ml-8 space-x-2">
        <PButton 
          color="secondary" 
          icon="file-excel"
          :loading="isDownloading"
          @click="onDownload"
        >
          Excel
        </PButton>
      </div>
    </portal>

  </div>
</template>

<script>

  import http from "@/http"
  import { mapGetters } from "vuex"
  import { orderBy } from "lodash"
  import { format } from "date-fns"
  import fileDownload from 'js-file-download'

  export default {
    
    name: "Users",
    
    data() {
      return {
        users: [],
        isLoading: false,
        isDownloading: false,
        links: [
          { route: "reportsHome", name: "Rapport > Brukere" },
        ]
      }
    },
    
    computed: {
      ...mapGetters([
        "customer"
      ])
    },

    methods: {

      async onDownload () {

        this.isDownloading = true

        try {
        
          const data = await http.get("Users", { 
            params: { 
              customerId: this.customer.id, 
              withDetails: true, 
              excel: "download" 
            },
            responseType: "blob"
          })
          
          fileDownload(data, `${format(new Date(), "yyyy-MM-dd")}_users.xlsx`)
        
        } 
        catch {

          //

        }

        this.isDownloading = false
      }
      
    },

    async created () {
      this.isLoading = true
      let users = await http.get("Users", { params: { customerId: this.customer.id, withDetails: true }});
      this.users = orderBy(users, ["userStatusId", "loginName"], ["desc", "asc"])
      this.isLoading = false
    },
    
  }
</script>

<style scoped>
  .inactive {
    @apply bg-red-300 !important;
  }
</style>